import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeroImage from "../assets/images/hero-image.png";
import HeroImageShadow from "../assets/images/hero-image-shadow.png";
import HeroImageFlares from "../assets/images/hero-image-flares.png";
import { InputMask } from "@react-input/mask";
import { SubmitHandler, useForm } from "react-hook-form";
import { Suspense, useCallback, useContext, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { AppContext } from "../providers/AppProvider";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  validateBirthDate,
  validateCPF,
  validateMail,
  validateName,
  validatePhone,
} from "../utils";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const app = useContext(AppContext);
  const api = useApi();

  const { formState, register, handleSubmit, setValue, setError, getValues } =
    useForm({
      defaultValues: {
        nome: "",
        cpf: "",
        data_nascimento: "",
        telefone: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
        aceite_regulamento: false,
        aceite_informacoes: false,
        recaptcha_token: "",
      },
    });

  const loadRecaptcha = useCallback(async () => {
    if (executeRecaptcha) {
      executeRecaptcha("register").then((token) =>
        setValue("recaptcha_token", token),
      );
    }
  }, [executeRecaptcha, setValue]);

  const handleSuccess = useCallback(
    (response: any) => {
      Swal.fire({
        icon: "success",
        title: "Cadastro efetuado com sucesso!",
        confirmButtonText: "Prosseguir",
        html: response.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        app.setToken(response.token);
        navigate(response.redirectTo);
        window.scrollTo(0, 0);
      });
    },
    [app, navigate],
  );

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data).forEach(([field, messages]) => {
        if (field === "recaptcha_token") {
          loadRecaptcha();
        }

        setError(field as any, { type: "manual", message: messages[0] });
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [loadRecaptcha, setError],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando o seu cadastro",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      data.aceite_regulamento = data.aceite_regulamento === "S" ? "S" : "N";
      data.aceite_informacoes = data.aceite_informacoes === "S" ? "S" : "N";

      api.register(data).then(handleSuccess).catch(handleError);
    },
    [api, handleError, handleSuccess],
  );

  useEffect(() => {
    register("recaptcha_token", {
      required:
        "O Google reCAPTCHA não foi carregado corretamente. Atualize a página e tente novamente.",
    });

    loadRecaptcha();
  }, [register, loadRecaptcha]);

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">CADASTRO</h1>

          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                className="mb-5"
                autoComplete="off"
                autoCorrect="off"
                noValidate
              >
                <div className="mb-4">
                  <h2 className="fs-5 mb-3">DADOS PESSOAIS</h2>

                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label htmlFor="nome">Nome Completo*</Form.Label>
                      <Form.Control
                        id="nome"
                        className="form-control"
                        isInvalid={!!formState.errors.nome}
                        {...register("nome", {
                          required: "Este campo é obrigatório",
                          validate: validateName,
                        })}
                        autoFocus
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.nome?.type === "minLength"
                          ? "Informe o seu nome completo"
                          : formState.errors.nome?.message}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Label htmlFor="cpf">CPF*</Form.Label>
                      <Form.Control
                        id="cpf"
                        as={InputMask}
                        mask="___.___.___-__"
                        placeholder="___.___.___-__"
                        replacement={{ _: /\d/ }}
                        isInvalid={!!formState.errors.cpf}
                        {...register("cpf", {
                          required: "Este campo é obrigatório",
                          validate: validateCPF,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.cpf?.message}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label htmlFor="data_nascimento">
                        Data da Nascimento*
                      </Form.Label>
                      <Form.Control
                        as={InputMask}
                        mask="__/__/____"
                        placeholder="__/__/____"
                        replacement={{ _: /\d/ }}
                        id="data_nascimento"
                        isInvalid={!!formState.errors.data_nascimento}
                        {...register("data_nascimento", {
                          required: "Este campo é obrigatório",
                          validate: validateBirthDate,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.data_nascimento?.message}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label htmlFor="telefone">Telefone*</Form.Label>
                      <Form.Control
                        as={InputMask}
                        mask="(__) _____-____"
                        placeholder="(__) _____-____"
                        replacement={{ _: /\d/ }}
                        id="telefone"
                        isInvalid={!!formState.errors.telefone}
                        {...register("telefone", {
                          required: "Este campo é obrigatório",
                          validate: validatePhone,
                        })}
                      />

                      <Form.Control.Feedback type="invalid">
                        {formState.errors.telefone?.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </div>

                <div className="mb-4">
                  <h2 className="fs-5 mb-3">DADOS DE ACESSO</h2>

                  <Row>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Label htmlFor="email">E-mail*</Form.Label>
                      <Form.Control
                        id="email"
                        type="email"
                        isInvalid={!!formState.errors.email}
                        {...register("email", {
                          required: "Este campo é obrigatório",
                          validate: validateMail,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.email?.message}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Label htmlFor="email_confirmation">
                        Confirmar E-mail*
                      </Form.Label>
                      <Form.Control
                        type="email"
                        id="email_confirmation"
                        isInvalid={!!formState.errors.email_confirmation}
                        {...register("email_confirmation", {
                          required: "Este campo é obrigatório",
                          validate: validateMail,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.email_confirmation?.message}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Label htmlFor="password">Senha*</Form.Label>
                      <Form.Control
                        type="password"
                        id="password"
                        isInvalid={!!formState.errors.password}
                        {...register("password", {
                          required: "Este campo é obrigatório",
                          minLength: 8,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.password?.type === "minLength"
                          ? "A senha deve ter no mínimo 8 caracteres"
                          : formState.errors.password?.message}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Label htmlFor="passwordConfirmation">
                        Confirmar Senha*
                      </Form.Label>
                      <Form.Control
                        type="password"
                        id="passwordConfirmation"
                        {...register("password_confirmation", {
                          required: "Este campo é obrigatório",
                          validate: (x) => {
                            return (
                              getValues("password") === x ||
                              "As senhas não coincidem"
                            );
                          },
                        })}
                        isInvalid={!!formState.errors.password_confirmation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.password_confirmation?.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col>
                    <Form.Check
                      value="S"
                      type="checkbox"
                      id="acceptTermsCheckbox"
                      label="Li e aceito os termos do Regulamento e da Política de Privacidade.*"
                      isInvalid={!!formState.errors.aceite_regulamento}
                      {...register("aceite_regulamento", {
                        required: "Este campo é obrigatório",
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Check
                      value="S"
                      type="checkbox"
                      id="acceptSocialCheckbox"
                      label="Quer receber, por e-mail, SMS e Whatsapp, informações
                        sobre produtos, ofertas, descontos e serviços das
                        empresas que compões o Grupo Carrefour Brasil."
                      isInvalid={!!formState.errors.aceite_informacoes}
                      {...register("aceite_informacoes")}
                    />
                  </Col>
                </Row>

                <p className="mt-3">* Campos de preenchimento obrigatório.</p>

                {formState.errors.recaptcha_token && (
                  <Alert variant="danger" className="mb-3">
                    {formState.errors.recaptcha_token.message}
                  </Alert>
                )}

                <div className="d-flex">
                  <Button
                    type="submit"
                    variant="primary"
                    className="button mx-auto"
                  >
                    CONFIRMAR
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>

          <div className="register-footer-image">
            <div className="hero__image">
              <Image
                className="hero__left__logo"
                src={HeroImage}
                alt=""
                fluid
              />
              <Image
                className="hero__left__shadow"
                src={HeroImageShadow}
                alt=""
                fluid
              />

              <Image
                className="hero__left__flares"
                src={HeroImageFlares}
                alt=""
                fluid
              />
            </div>
          </div>
        </Container>
      </section>
    </Suspense>
  );
}
